import React, { useContext } from 'react'
import HighlightedBox from '../components/HighlightedBox'
import { colors } from '../components/styles/Colors'
import Button from '../components/Button'
import PopupLikeFieldWrapper from '../components/PopupLikeFieldWrapper'
import Checkbox from '../components/Checkbox'
import { CheckboxType } from '../enums/CheckboxType'
import { TocContext } from '../models/Context'
import { useRedirectToPage } from '../hooks/useRedirectToPage'
import { kRoutes } from '../utils/Routes'

const pageRoute = kRoutes.Home

export default function Home() {
    const { appData } = useContext(TocContext)
    const [user, , setComplete, step, setStep, onUserChanged] = appData // removed _ due to cflare lint error
    useRedirectToPage(pageRoute, step)

    return (
        <section className="flex flex-col items-center justify-center h-screen mx-5 text-center step__one animation__fade_in">
            <header>
                <h1 className="text-4xl font-semibold text-gray-800 lg:text-6xl">
                    Sign up to be a Volunteer!
                </h1>
                <p className="mt-5 leading-6 text-gray-700 text-md lg:leading-loose">
                    Thank you for your interest in volunteering or offering your
                    skills for the glory of God and the benefit of our
                    ministry.
                    <br />
                    <span className="font-semibold">
                        Your details will be received in steps - the first step
                        follows.
                    </span>
                </p>
            </header>

            <article className="my-12">
                <HighlightedBox
                    style={{
                        backgroundColor: colors.primary.opacity('1.5%'),
                    }}
                    content={
                        <div className="flex flex-col items-center justify-center">
                            <h6 className="leading-loose">
                                This is a faith-based organization and some of
                                the questions asked here are faith-based.
                            </h6>
                            <p>
                                <a
                                    href="https://torchofchrist.com/our-mission-and-vision"
                                    className="leading-loose text-teal-500"
                                >
                                    Click for more details
                                </a>
                            </p>
                        </div>
                    }
                />


                <PopupLikeFieldWrapper
                    className="mt-12 acceptance-box animation__fade_in"
                    title="Do you believe in the death, burial, and resurrection of Jesus Christ?"
                    body={
                        <div className="flex flex-col justify-around py-4 lg:flex-row">
                            <Checkbox
                                type={CheckboxType.radio}
                                selected={user.disclaimerAccepted}
                                onSelectionChange={() =>
                                    onUserChanged({
                                        disclaimerAccepted: true,
                                    })
                                }
                            >
                                <span className="text-sm">
                                    Yes, I believe that Jesus is the Christ
                                </span>
                            </Checkbox>
                            <Checkbox
                                type={CheckboxType.radio}
                                selected={!user.disclaimerAccepted}
                                onSelectionChange={() =>
                                    onUserChanged({
                                        disclaimerAccepted: false,
                                    })
                                }
                            >
                                <span className="text-sm">
                                    No, I do not believe that Jesus is the
                                    Christ
                                </span>
                            </Checkbox>
                        </div>
                    }
                />
            </article>

            <footer className="flex w-full pb-10 md:justify-center">
                <Button
                    className="w-full px-5 py-3 app-button md:w-auto"
                    disabled={!user.disclaimerAccepted}
                    title={
                        !user.disclaimerAccepted
                            ? 'We only accept submissions, from people who believe Jesus is the Christ'
                            : ''
                    }
                    onClick={() => {
                        setComplete(false)
                        setStep(kRoutes.PersonalDetails)
                    }}
                >
                    <div className="flex items-center justify-between w-full">
                        Continue
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 ml-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                    </div>
                </Button>
            </footer>
        </section>
    )
}
