// @flow
import * as React from 'react'
import { colors } from './styles/Colors'

interface Props {
    icon: JSX.Element
    title: string
    body: JSX.Element | string
    selected: boolean
    className?: string
    onClick: () => void
}

export function HighlightCardButton({
    icon,
    title,
    body,
    selected,
    className,
    onClick,
}: Props) {
    const selectedWrapperStyle = {
        boxShadow: `0 0 2px 2px ${colors.primary.solid}`,
        // cursor: 'pointer',
    }
    const accent = { color: selected ? colors.primary.solid : '' }

    return (
        <div
            className={`px-5 py-4 bg-white rounded-sm w-full cursor-pointer ${className} ${
                selected ? '' : 'border-2'
            }`}
            style={selected ? selectedWrapperStyle : {}}
            onClick={onClick}
        >
            <div className="flex flex-row items-center">
                <div className="icon mr-4" style={accent}>
                    {icon}
                </div>
                <div>
                    <h3
                        className="text-2xl text-gray-700 font-semibold"
                        style={accent}
                    >
                        {title}
                    </h3>
                    <p className="text-base text-gray-600 font-light">{body}</p>
                </div>
            </div>
        </div>
    )
}
