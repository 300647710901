import { useState } from 'react'
import { Step } from '../enums/Step'
import { ITocStore, TocStore } from '../models/Store'
import { User } from '../models/User'
import { Route } from '../utils/Routes'
import { useLocalStorage } from './useLocalStorage'

type Complete = boolean
type SetComplete = Function
type SetStep = (route: Route) => void

export const USER_STORAGE_KEY = 'toc-user'
export const LATEST_STEP_KEY  = 'step-key'

export type UserAppDataProps = [
    User,
    Complete,
    SetComplete,
    Step,
    SetStep,
    (user: Partial<User>) => void
]

export function useAppData(
    _complete: boolean,
    initialStep: Step,
    store: ITocStore,
    setStore: Function
): UserAppDataProps {
    const [complete, setComplete] = useState(_complete)
    const [step, setStep] = useState(initialStep)
    const { update: updateUserInStorage } = useLocalStorage(USER_STORAGE_KEY)

    const onUserChanged = (user: Partial<User>): void => {
        store.updateUser(user)
        updateUserInStorage(store.user)
        setStore(new TocStore(store.user))
    }

    const setRoute = (newRoute: Route) => {
        setStep(newRoute.step);
    }

    return [store.user, complete, setComplete, step, setRoute, onUserChanged]
}
