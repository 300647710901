import React from "react";
import {Orientation} from "../enums/Orientation";

interface Props {
    orientation?: Orientation;
    hideBottomMargin?: boolean;
    className?: string;
}

export class FieldSet extends React.Component<Props, any> {
    static Label = ({children}: any) => <label className="font-semibold text-gray-800">{children}</label>
    static Field = ({children}: any) => <>{children}</>


    render() {
        const {orientation, children, hideBottomMargin, className} = this.props;

        return (
            <div className={`flex gap-2 ${orientation === Orientation.HORIZONTAL ? "flex-row" : "flex-col"} ${hideBottomMargin ? '' : 'mb-8'} ${className}`}>
                {children}
            </div>
        );
    }
}
