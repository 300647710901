import React from 'react'
import { colors } from './styles/Colors'

interface Props {
    removable?: boolean
    children?: JSX.Element | string
    className?: string
    onClick?: () => void
    onRemove?: () => void
}

export default function Pill({
    removable = true,
    onRemove,
    onClick,
    children,
    className,
}: Props) {
    return (
        <div
            className={`rounded-full inline-block py-1 text-sm text-white cursor-pointer ${className} ${
                removable ? 'pl-6 pr-4' : 'px-6'
            }`}
            style={{ backgroundColor: colors.primary.solid }}
            onClick={() => onClick && onClick()}
        >
            {children}
            {removable && (
                <button
                    className="ml-2 font-bold cursor-pointer focus:outline-none hover:text-gray-300 transition-colors duration-200 pointer-events-auto"
                    title="Remove"
                    aria-label="Remove"
                    tabIndex={-1}
                    onClick={(e) => {
                        e.stopPropagation()
                        return onRemove && onRemove()
                    }}
                >
                    &times;
                </button>
            )}
        </div>
    )
}
