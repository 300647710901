import React, { useContext, useEffect } from 'react'
import { SteppedPage } from './SteppedPage'
import { FieldSet } from '../components/FieldSet'
import { InputField, InputFieldType } from '../components/InputField'
import { AnyContactInfo, EmailContact, PhoneContact } from '../models/User'
import Checkbox from '../components/Checkbox'
import { Gender } from '../enums/Gender'
import Dropdown, { DropdownSelectOption } from '../components/Dropdown'
import { ContactMethod } from '../enums/ContactMethod'
import PopupLikeFieldWrapper from '../components/PopupLikeFieldWrapper'
import { CheckboxType } from '../enums/CheckboxType'
import { Orientation } from '../enums/Orientation'
import { nonEmpty } from '../utils/TextUtils'
import { countries } from '../services/CountryService'
import { TocContext } from '../models/Context'
import { useRedirectToPage } from '../hooks/useRedirectToPage'
import { kRoutes } from '../utils/Routes'
import { fieldValidators } from '../utils/Validators'
import HighlightedBox from '../components/HighlightedBox'
import { colors } from '../components/styles/Colors'

const countryList: DropdownSelectOption<String>[] = countries.map((c) => ({
    title: c.name,
    value: c.code || c.name,
}))

// step for this screen.
const pageRoute = kRoutes.PersonalDetails

export const PersonalDetails = () => {
    const { appData } = useContext(TocContext)
    const [user, complete, setComplete, step, setStep, onUserChanged] = appData
    const validator = fieldValidators.personalPage

    useRedirectToPage(pageRoute, step)

    useEffect(() => {
        setComplete(
            nonEmpty(user.fullName) &&
                nonEmpty(user.gender) &&
                !!user.age &&
                user.age > 0 &&
                nonEmpty(user?.country?.name) &&
                user?.country?.name !== 'Select Country' &&
                user.contactInfo &&
                user.contactInfo.length !== 0 &&
                user.contactInfo.every((ci) => {
                    if (!ci.enabled) {
                        return true
                    }
                    if (ci.method === ContactMethod.EMAIL) {
                        return nonEmpty(ci.email)
                    } else {
                        return (
                            nonEmpty(ci.phone) &&
                            (ci.preferCall || ci.preferText)
                        )
                    }
                })
        )
    }, [user, setComplete])

    const updateContactMethod = (
        contactInfo: AnyContactInfo,
        enabled = true
    ): void => {
        if (!user.contactInfo) {
            user.contactInfo = []
        }
        let cMethod: AnyContactInfo = user.contactInfo.find(
            (cInfo) => cInfo.method === contactInfo.method
        ) as any

        if (cMethod == null) {
            cMethod = { ...contactInfo, enabled }
            user.contactInfo.push(cMethod as any)
        }
        Object.assign(cMethod, contactInfo, { enabled })
        onUserChanged(user)
    }
    const emailContact: EmailContact = user.contactInfo.find(
        (cm) => cm.method === ContactMethod.EMAIL
    ) as EmailContact
    const phoneContact: PhoneContact = user.contactInfo.find(
        (cm) => cm.method === ContactMethod.PHONE
    ) as PhoneContact

    // VALIDATION
    validator.fullName.validate(user.fullName)
    validator.age.validate(user.age)
    validator.country.validate(user.country)

    // console.log(validator.fullName.isValid)

    // validate email
    if (emailContact?.enabled) {
        validator.email.validate(emailContact.email)
    } else {
        validator.email.isValid = true
    }

    // validate phone number
    if (phoneContact?.enabled) {
        validator.phone.validate(phoneContact.phone)
    } else {
        validator.phone.isValid = true
    }

    return (
        <SteppedPage
            step={step}
            complete={complete}
            onPreviousClick={() => setStep(kRoutes.Home)}
            onNextClick={() => {
                setStep(kRoutes.MissionAndSkill)
            }}
        >
            <SteppedPage.Title>Personal Details</SteppedPage.Title>
            <SteppedPage.Subtitle>
                Thank you for your interest in volunteering or offering your
                skills for the glory of God and the benefit of our ministry.
                <span className="font-semibold">
                    &nbsp; Your details will be received in steps - this is the
                    first step.
                </span>
            </SteppedPage.Subtitle>
            <SteppedPage.Content>
                <FieldSet orientation={Orientation.VERTICAL}>
                    <FieldSet.Label>What is your full name?</FieldSet.Label>
                    <FieldSet.Field>
                        <InputField
                            type={InputFieldType.TEXT}
                            placeholder="John Smith"
                            defaultValue={user.fullName}
                            invalid={!validator.fullName.isValid}
                            onInput={(e) =>
                                onUserChanged({
                                    fullName: e.currentTarget.value,
                                })
                            }
                        />
                    </FieldSet.Field>
                </FieldSet>
                <FieldSet>
                    <FieldSet.Label>Gender</FieldSet.Label>
                    <FieldSet.Field>
                        <div className="flex gap-10">
                            <Checkbox
                                type={CheckboxType.radio}
                                selected={user.gender === Gender.MALE}
                                onSelectionChange={() =>
                                    onUserChanged({ gender: Gender.MALE })
                                }
                            >
                                Male
                            </Checkbox>
                            <Checkbox
                                type={CheckboxType.radio}
                                selected={user.gender === Gender.FEMALE}
                                onSelectionChange={() =>
                                    onUserChanged({ gender: Gender.FEMALE })
                                }
                            >
                                Female
                            </Checkbox>
                        </div>
                    </FieldSet.Field>
                </FieldSet>
                <FieldSet>
                    <FieldSet.Label>Your Age</FieldSet.Label>
                    <FieldSet.Field>
                        <InputField
                            type={InputFieldType.NUMBER}
                            placeholder="28"
                            defaultValue={user.age}
                            invalid={!validator.age.isValid}
                            onInput={(e) =>
                                onUserChanged({
                                    age: !!parseInt(e.currentTarget.value)
                                        ? parseInt(e.currentTarget.value)
                                        : 0,
                                })
                            }
                        />
                    </FieldSet.Field>
                </FieldSet>
                <FieldSet>
                    <FieldSet.Label>
                        In what country do you live?
                    </FieldSet.Label>
                    <FieldSet.Field>
                        <Dropdown
                            options={countryList}
                            invalid={!validator.country.isValid}
                            defaultSelection={{
                                value: user.country?.code || user.country?.name,
                                title: '',
                            }}
                            onSelectionChange={(country) =>
                                onUserChanged({
                                    country: {
                                        name: country.title,
                                        code: country.value as string,
                                    },
                                })
                            }
                        />
                    </FieldSet.Field>
                </FieldSet>
                <FieldSet>
                    <FieldSet.Label>
                        Which is the best way to contact you?
                        <p className="mt-1 text-xs font-thin text-gray-600">
                            Click all that apply
                        </p>
                    </FieldSet.Label>
                    <FieldSet.Field>
                        <Checkbox
                            selected={emailContact?.enabled}
                            onSelectionChange={(enabled) =>
                                updateContactMethod(
                                    { method: ContactMethod.EMAIL },
                                    enabled
                                )
                            }
                        >
                            Email
                        </Checkbox>
                        {emailContact?.enabled && (
                            <PopupLikeFieldWrapper
                                className="my-2 animation__fade_in"
                                title="What is your email?"
                                body={
                                    <FieldSet hideBottomMargin={true}>
                                        <FieldSet.Field>
                                            <InputField
                                                type={InputFieldType.TEXT}
                                                placeholder="Enter your email address"
                                                defaultValue={
                                                    emailContact?.email
                                                }
                                                invalid={
                                                    !validator.email.isValid
                                                }
                                                onInput={(e) =>
                                                    updateContactMethod({
                                                        method: ContactMethod.EMAIL,
                                                        email: e.currentTarget
                                                            .value,
                                                    })
                                                }
                                            />
                                        </FieldSet.Field>
                                        <FieldSet.Label>
                                            <p className="text-xs font-thin text-gray-600">
                                                e.g. personal@example.com
                                            </p>
                                        </FieldSet.Label>
                                        {(!phoneContact?.enabled || (!phoneContact?.enabled && !phoneContact?.preferCall)) && (
                <HighlightedBox
                    className="mb-8 bg-blue-100 animation__fade_in"
                    style={{
                        backgroundColor: '#F9FCFF',
                        boxShadow: `0 0 2px 2px ${colors.primary.opacity(
                            '45%'
                        )}`,
                    }}
                    content={
                        <div className="flex flex-col">
                            <div className="flex items-center gap-4 mb-4">
                                <h4
                                    className="font-semibold"
                                    style={{
                                        color: colors.primary.solid,
                                    }}
                                >
                                    Please Note
                                </h4>
                                <svg
                                    width="18"
                                    height="19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.947 2.538L16 10.168l-6.55 6.888-7.064-7.62"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M2.386 9.437L2 2.132l6.947.406"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        clipRule="evenodd"
                                        d="M7.03 6.99c.141.15.105.414-.053.58-.157.164-.399.177-.54.028-.143-.15-.13-.404.027-.569.157-.165.423-.189.565-.04v0z"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            <p>
                                Torch of Christ Ministries may ask to schedule a call before a mission trip is planned.
                            </p>
                        </div>
                    }
                />
            )}
                                    </FieldSet>
                                }
                            />
                        )}
                        <Checkbox
                            selected={phoneContact?.enabled}
                            onSelectionChange={(enabled) =>
                                updateContactMethod(
                                    { method: ContactMethod.PHONE },
                                    enabled
                                )
                            }
                        >
                            Phone
                        </Checkbox>
                        {phoneContact?.enabled && (
                            <PopupLikeFieldWrapper
                                className="my-2 animation__fade_in"
                                title="What is your phone number?"
                                body={
                                    <>
                                        <FieldSet hideBottomMargin={true}>
                                            <FieldSet.Field>
                                                <InputField
                                                    type={InputFieldType.TEXT}
                                                    placeholder="Enter your phone number"
                                                    defaultValue={
                                                        phoneContact?.phone
                                                    }
                                                    invalid={
                                                        !validator.phone.isValid
                                                    }
                                                    onInput={(e) =>
                                                        updateContactMethod({
                                                            method: ContactMethod.PHONE,
                                                            phone: e
                                                                .currentTarget
                                                                .value,
                                                        })
                                                    }
                                                />
                                            </FieldSet.Field>
                                        </FieldSet>
                                        <div className="my-5 border border-b-0 border-gray-500 border-dashed" />
                                        <FieldSet hideBottomMargin={true}>
                                            <FieldSet.Label>
                                                Do you prefer we Call or send
                                                you a Text?
                                            </FieldSet.Label>
                                            <FieldSet.Field>
                                                <div className="flex gap-8">
                                                    <Checkbox
                                                        selected={
                                                            phoneContact?.preferCall
                                                        }
                                                        onSelectionChange={(
                                                            preferCall
                                                        ) =>
                                                            updateContactMethod(
                                                                {
                                                                    method: ContactMethod.PHONE,
                                                                    preferCall,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        Call Me
                                                    </Checkbox>
                                                    <Checkbox
                                                        selected={
                                                            phoneContact?.preferText
                                                        }
                                                        onSelectionChange={(
                                                            preferText
                                                        ) =>
                                                            updateContactMethod(
                                                                {
                                                                    method: ContactMethod.PHONE,
                                                                    preferText,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        Text Me
                                                    </Checkbox>
                                                </div>
                                            </FieldSet.Field>
                                            {!phoneContact?.preferCall && (
                <HighlightedBox
                    className="mb-8 bg-blue-100 animation__fade_in"
                    style={{
                        backgroundColor: '#F9FCFF',
                        boxShadow: `0 0 2px 2px ${colors.primary.opacity(
                            '45%'
                        )}`,
                    }}
                    content={
                        <div className="flex flex-col">
                            <div className="flex items-center gap-4 mb-4">
                                <h4
                                    className="font-semibold"
                                    style={{
                                        color: colors.primary.solid,
                                    }}
                                >
                                    Please Note
                                </h4>
                                <svg
                                    width="18"
                                    height="19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.947 2.538L16 10.168l-6.55 6.888-7.064-7.62"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M2.386 9.437L2 2.132l6.947.406"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        clipRule="evenodd"
                                        d="M7.03 6.99c.141.15.105.414-.053.58-.157.164-.399.177-.54.028-.143-.15-.13-.404.027-.569.157-.165.423-.189.565-.04v0z"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            <p>
                                Torch of Christ Ministries may ask to schedule a call before a mission trip is planned.
                            </p>
                        </div>
                    }
                />
            )}
                                        </FieldSet>
                                        
                                    </>
                                }
                            />
                        )}
                    </FieldSet.Field>
                </FieldSet>
            </SteppedPage.Content>
        </SteppedPage>
    )
}
