import React from 'react'
import {
    Stepper,
    StepperHandleState,
    StepperProps,
} from '../components/Stepper'
import { AllSteps, Step } from '../enums/Step'
import Button from '../components/Button'

interface Props {
    step: Step
    complete: boolean
    isLastStep?: boolean
    onPreviousClick?: () => void
    onNextClick?: () => void
    onSubmitClick?: () => void
    stepperProps?: StepperProps
    className?: string
}

export class SteppedPage extends React.Component<Props, any> {
    static Title = ({ children }: any) => (
        <header>
            <h3 className="mb-4 -mt-2 text-3xl font-semibold">{children}</h3>
        </header>
    )
    static Subtitle = ({ children, className }: any) => (
        <p className="mb-10 font-light text-gray-700">
            <span className={className}>{children}</span>
        </p>
    )
    static Content = ({ children }: any) => <div>{children}</div>

    render() {
        const {
            step,
            complete,
            isLastStep,
            onPreviousClick,
            onNextClick,
            onSubmitClick,
            className,
        } = this.props
        const makeHandleState = (s: Step) => {
            if (s === step) {
                return complete
                    ? StepperHandleState.FULL
                    : StepperHandleState.HALF_FULL
            } else {
                return s.valueOf() < step
                    ? StepperHandleState.FULL
                    : StepperHandleState.EMPTY
            }
        }
        const primaryButtonContent = (text: string) => (
            <div className="flex items-center justify-between w-full md:w-auto">
                {text}
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 ml-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
            </div>
        )

        return (
            <div
                className={`component__stepped_wrapper animation__fade_in w-full flex flex-col justify-center items-center mt-6 lg:mt-32 ${className}`}
            >
                <div className="flex flex-col justify-center w-full xl:w-3/5 lg:flex-row">
                    <div className="grid grid-flow-col mx-6 lg:flex lg:items-center lg:flex-col lg:mx-8"
                            style={{gridTemplateColumns: "1fr 1fr 1fr auto"}}>
                        {AllSteps.map((s: Step, index: number) => {
                            return (
                                <Stepper
                                    key={index}
                                    active={s === step}
                                    complete={s.valueOf() < step}
                                    hasHandle={index + 1 !== AllSteps.length}
                                    handleState={makeHandleState(s)}
                                    value={s.valueOf()}
                                />
                            )
                        })}
                    </div>
                    <div className="flex-grow mx-6 mt-8 content-area lg:ml-16 lg:mt-auto">
                        <div className="mb-16">{this.props.children}</div>
                        <footer className="flex flex-col-reverse items-stretch justify-center mb-16 md:flex-row md:items-center md:justify-between">
                            <Button className="self-center text-gray-500 uppercase mt-7 md:mt-0" style={{}} onClick={onPreviousClick}>
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                                    </svg>
                                    <span className="pl-2">Previous Step</span>
                                </div>
                            </Button>

                            {!isLastStep && (
                                <Button
                                    className="px-5 py-3 app-button"
                                    disabled={!complete}
                                    onClick={onNextClick}
                                >
                                    {primaryButtonContent("Next Step")}
                                </Button>
                            )}

                            {isLastStep && (
                                <Button className="px-5 py-3 app-button" disabled={!complete} onClick={onSubmitClick}>
                                    {primaryButtonContent("Submit")}
                                </Button>
                            )}
                        </footer>
                    </div>
                </div>
            </div>
        )
    }
}
