import { User, UserMethods } from './User'

export interface ITocStore {
    user: User
    updateUser: (user: Partial<User>) => void
}

export class TocStore implements ITocStore {
    user: User

    constructor(user: User) {
        this.user = user
    }

    updateUser = (user: Partial<User>) => {
        // this.user = { ...this.user, ...user }
        this.user = UserMethods.copy(this.user, user)
    }
}
