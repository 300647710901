import { Dictionary } from 'lodash'
import { ContactMethod } from '../enums/ContactMethod'
import { User } from '../models/User'
import { nonEmpty } from './TextUtils'
import { GroupValidator, Validator, validators } from './Validator'

export const isBasicDataComplete = (user: User): boolean => {
    return (
        nonEmpty(user.fullName) &&
        nonEmpty(user.gender) &&
        !!user.age &&
        user.age > 0 &&
        nonEmpty(user?.country?.name) &&
        user.contactInfo &&
        user.contactInfo.length !== 0 &&
        user.contactInfo.every((ci) => {
            if (!ci.enabled) {
                return true
            }
            if (ci.method === ContactMethod.EMAIL) {
                return nonEmpty(ci.email)
            } else {
                return (
                    nonEmpty(ci.phone) &&
                    (ci.preferCall || ci.preferText)
                )
            }
        })
    )
}

export const isMissionDataComplete = (user: User): boolean => {
    if (!user.volunteerInfo?.[0]?.enabled) return true

    // validate mission data
    const mission = user.volunteerInfo[0]

    return (
        (mission.travelRestrictions != null && !mission.travelRestrictions) &&
        mission.passportCountry != null &&
        mission.passportCountry.name !== "Select Country" &&
        mission.yearsOfExperience != null &&
        (mission.yearsOfExperience == null || mission.yearsOfExperience === 0 ||
            (mission.yearsOfExperience !== 0 &&
                nonEmpty(mission.pastMissionDescription))) &&
        mission.languages != null &&
        mission.languages.length !== 0 &&
        (!mission.healthConcerns || nonEmpty(mission.healthConcernDescription))
    )
}

export const isSkillDataComplete = (user: User): boolean => {
    if (!user.volunteerInfo?.[1]?.enabled) return true

    // validate skill data
    const mission = user.volunteerInfo[1]

    return (
        nonEmpty(mission.educationalBackground) &&
        mission.languages != null &&
        mission.languages.length !== 0 &&
        mission.skills != null &&
        mission.skills.length !== 0
    )
}

export interface PageValidator {
    [page: string]: Dictionary<Validator<any>>
}

export const fieldValidators: PageValidator = {
    personalPage: {
        fullName: validators
            .required()
            .withError('Please enter your full name'),
        age: new GroupValidator(
            validators.required().withError('Your age is required'),
            validators
                .number({ min: 21, max: 50 }) // TODO: Confirm this is ok
                .withError('You must be between 21 and 50 years old')
        ),
        country: validators.required().withError('Please select a country'),
        email: new GroupValidator(
            validators.required().withError('Enter your email address'),
            validators.email().withError('Email address is invalid')
        ),
        phone: new GroupValidator(
            validators.required().withError('Enter your phone number'),
            validators
                .phone()
                .withError('Enter phone number in format: ###-###-####')
        ),
    },
}
