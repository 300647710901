import React, { FormEvent } from 'react'
import '../styles/components/FormField.css'

interface Props {
    id?: string
    name?: string
    cols?: number
    rows?: number
    defaultValue?: string | number | readonly string[] | undefined
    disabled?: boolean
    placeholder?: string
    title?: string
    onInput?: (e: FormEvent<HTMLTextAreaElement>) => void
}

export const TextArea = ({
    id,
    name,
    cols,
    rows,
    defaultValue,
    disabled,
    placeholder,
    title,
    onInput,
}: Props) => (
    <textarea
        id={id}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        cols={cols}
        rows={rows}
        defaultValue={defaultValue}
        title={title}
        onInput={onInput}
        className="app__text-area transition__box-shadow px-3 py-2 border text-gray-800 rounded hover:shadow-xs"
    ></textarea>
)
