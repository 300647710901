interface RGB {
    r: number
    g: number
    b: number
}

class Color {
    private rgb: RGB

    get solid() {
        const { r, g, b } = this.rgb
        return `rgb(${r}, ${g}, ${b})`
    }

    constructor(rgb: RGB) {
        this.rgb = rgb
    }

    opacity(transparency: string) {
        const { r, g, b } = this.rgb
        return `rgba(${r}, ${g}, ${b}, ${transparency})`
    }
}

export const colors = {
    base: {
        white: '#ffffff',
        lightGray: '#CBD5E0',
    },
    primary: new Color({ r: 206, g: 104, b: 73 }),
    border: '#ffffff',
}
