import { MissionVolunteer, SkillVolunteer } from './Volunteer'
import { Gender } from '../enums/Gender'
import { Country } from './Country'
import { ContactMethod } from '../enums/ContactMethod'

// export type AnyVolunteerInfo = MissionVolunteer | SkillVolunteer
export type AnyContactInfo = PhoneContact | EmailContact

export interface User {
    disclaimerAccepted: boolean

    fullName?: string
    gender?: Gender
    age?: number
    country?: Country
    contactInfo: AnyContactInfo[]

    volunteerInfo?: [MissionVolunteer?, SkillVolunteer?]
}

export class UserMethods {
    static copy<T extends {}>(obj: T, additionalProperties: Partial<{[K in keyof T]: T[K]}> = {}): T {
        return { ...obj, ...additionalProperties };
    }
}

export interface ContactInfo {
    method: ContactMethod,
    enabled?: boolean,
}

export interface EmailContact extends ContactInfo {
    method: ContactMethod.EMAIL
    email?: string
}

export interface PhoneContact extends ContactInfo {
    method: ContactMethod.PHONE
    phone?: string
    preferCall?: boolean
    preferText?: boolean
    //phoneTime?: string
}
