import { Dictionary } from 'lodash'
import { Step } from '../enums/Step'

export interface Route {
    path: string
    step: Step
}

export const kRoutes: Dictionary<Route> = {
    Home: {
        path: '/home',
        step: Step.ONE,
    },
    PersonalDetails: {
        path: '/personal',
        step: Step.TWO,
    },
    MissionAndSkill: {
        path: '/mission-and-skill',
        step: Step.THREE,
    },
    MissionSubPage: {
        path: '/mission-and-skill',
        step: Step.THREE,
    },
    SkillSubPage: {
        path: '/mission-and-skill',
        step: Step.THREE,
    },
    SummaryPage: {
        path: '/summary',
        step: Step.FOUR,
    },
    ThankYouPage: {
        path: '/thank-you',
        step: Step.FIVE,
    }
}
