export abstract class Validator<T> {
    protected error?: string
    isValid: boolean = true
    get message(): string | undefined {
        return this.isValid ? '' : this.error
    }
    abstract validate(value?: T): boolean

    withError(error: string): Validator<T> {
        this.error = error
        return this
    }
}

export class RequiredValidator<T> extends Validator<T> {
    validate(value?: any): boolean {
        if (typeof value === 'string')
            return (this.isValid = value != null && value.trim() !== '')
        else return (this.isValid = value != null)
    }
}

export class NumberValidator extends Validator<number> {
    min?: number
    max?: number

    constructor(min?: number, max?: number) {
        super()
        this.min = min
        this.max = max
    }

    validate(value: number): boolean {
        if (this.min != null) {
            this.isValid = value >= this.min
        }

        if (this.isValid && this.max != null) {
            this.isValid = value <= this.max
        }
        return this.isValid
    }
}

export class EmailValidator extends Validator<string> {
    validate(value: string): boolean {
        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return (this.isValid = emailRegex.test(value))
    }
}

export class PhoneNumberValidator extends Validator<string> {
    validate(value: string): boolean {
        const phoneRegex =
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
        return (this.isValid = phoneRegex.test(value))
    }
}

type DoValidate = (v: Validator<any>) => boolean

export class GroupValidator extends Validator<any> {
    validators: Validator<any>[]

    get message(): string | undefined {
        return this.validators.find((v) => !v.isValid)?.message
    }

    constructor(...validators: Validator<any>[]) {
        super()
        this.validators = validators
    }

    validate(value: any): boolean {
        return (this.isValid = this.validators.every(this.doValidate(value)))
    }

    doValidate(value: any): DoValidate {
        return (v: Validator<any>) => (v.isValid = v.validate(value))
    }
}

export const validators = {
    required: () => new RequiredValidator<any>(),
    number: ({ min, max }: { min?: number; max?: number }) =>
        new NumberValidator(min, max),
    email: () => new EmailValidator(),
    phone: () => new PhoneNumberValidator(),
}
