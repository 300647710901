import React from 'react'
import { UserAppDataProps } from '../hooks/useAppData'
import { ITocStore } from './Store'

interface TocContextData {
    store: ITocStore
    appData: UserAppDataProps
}

export const TocContext = React.createContext({
    store: { user: {} },
} as TocContextData)
