export enum Step {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
    // SIX = 6,
    // SEVEN = 7,
}

export const AllSteps: Step[] = [
    Step.ONE,
    Step.TWO,
    Step.THREE,
    Step.FOUR,
    // Step.FIVE,
    // Step.SIX,
    // Step.SEVEN,
]
