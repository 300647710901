import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import PopupLikeFieldWrapper from '../components/PopupLikeFieldWrapper'
import { colors } from '../components/styles/Colors'
import { ContactMethod } from '../enums/ContactMethod'
import { RateType } from '../enums/RateType'
import { LATEST_STEP_KEY } from '../hooks/useAppData'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { useRedirectToPage } from '../hooks/useRedirectToPage'
import { TocContext } from '../models/Context'
import { AnyContactInfo, EmailContact, PhoneContact } from '../models/User'
import { UserService } from '../services/UserService'
import { kRoutes } from '../utils/Routes'
import { isBasicDataComplete, isMissionDataComplete } from '../utils/Validators'
import { SteppedPage } from './SteppedPage'

// step for this screen.
const pageRoute = kRoutes.SummaryPage

export default function SummaryPage() {
    const { appData } = useContext(TocContext)
    const [user, , , step, setStep, ] = appData // eslint-disable-line no-unused-vars
    const history = useHistory();
    useRedirectToPage(pageRoute, step)
    //const { clear: clearUserStorage } = useLocalStorage(USER_STORAGE_KEY) // eslint-disable-line no-unused-vars
    const { clear: clearStepStorage } = useLocalStorage(LATEST_STEP_KEY)

    const getEnabledContactMethod = (
        method: ContactMethod
    ): AnyContactInfo | undefined =>
        user.contactInfo.find((ci) => ci.method === method && ci.enabled)

    const missionInfo = user.volunteerInfo?.[0]
    const skillInfo = user.volunteerInfo?.[1]

    const isComplete = isBasicDataComplete(user) && isMissionDataComplete(user)

    return (
        <SteppedPage
            step={step}
            complete={isComplete}
            isLastStep={true}
            onPreviousClick={() => setStep(kRoutes.MissionAndSkill)}
            onSubmitClick={async () => {
                console.log(user)
                const volunteer = await new UserService().createUser(user)

                if (volunteer != null) {
                    //clearUserStorage()
                    clearStepStorage()

                    // TODO: clear application context.
                    history.push(kRoutes.ThankYouPage.path);
                }

                console.log(volunteer)
            }}
            className="animation__fade_in"
        >
            <SteppedPage.Title>Summary</SteppedPage.Title>
            <SteppedPage.Subtitle className="text-gray-500">
                Below is a summary of the details you entered. Please
                check to confirm that these details are correct.
            </SteppedPage.Subtitle>
            <SteppedPage.Content>
                <Summary>
                    <Summary.Content>{user.fullName}</Summary.Content>
                    <Summary.Label>Full Name</Summary.Label>
                </Summary>
                <div className="flex">
                    <Summary className="w-1/3">
                        <Summary.Content>{user.gender}</Summary.Content>
                        <Summary.Label>Gender</Summary.Label>
                    </Summary>
                    <Summary className="w-1/3">
                        <Summary.Content>{user.age}</Summary.Content>
                        <Summary.Label>Age</Summary.Label>
                    </Summary>
                </div>
                <Summary>
                    <Summary.Content>{user.country?.name}</Summary.Content>
                    <Summary.Label>Country of Residence</Summary.Label>
                </Summary>
                <Summary>
                    <Summary.Content>
                        {user.contactInfo.filter(c => c.enabled).map((c) => c.method).join(' + ')}
                    </Summary.Content>
                    <Summary.Label>Method of Contact</Summary.Label>
                </Summary>
                <Summary>
                    <PopupLikeFieldWrapper
                        className="self-start"
                        body={
                            <div className="flex flex-col">
                                {(() => {
                                    const phoneContact =
                                        getEnabledContactMethod(
                                            ContactMethod.PHONE
                                        ) as PhoneContact
                                    return (
                                        phoneContact && (
                                            <span>
                                                {phoneContact.phone},{' '}
                                                {[
                                                    phoneContact.preferCall
                                                        ? 'Call'
                                                        : '',
                                                    phoneContact.preferText
                                                        ? 'Text'
                                                        : '',
                                                ]
                                                    .filter((pc) => pc !== '')
                                                    .join(' + ')}
                                            </span>
                                        )
                                    )
                                })()}
                                <span>
                                    {
                                        (
                                            getEnabledContactMethod(
                                                ContactMethod.EMAIL
                                            ) as EmailContact
                                        )?.email
                                    }
                                </span>
                            </div>
                        }
                        showNotch={false}
                    />
                </Summary>
                <Summary>
                    <Summary.Content>
                        {user.volunteerInfo
                            ?.filter((v) => v?.enabled)
                            .map((v) => v?.type)
                            .join(' + ')}
                    </Summary.Content>
                    <Summary.Label>
                        Volunteering for Mission or Offering Skills
                    </Summary.Label>
                </Summary>
                <div className="mb-4">
                    <span
                        style={{ color: colors.primary.solid }}
                        className="font-semibold"
                    >
                        Missions Information
                    </span>
                    <hr />
                </div>
                <Summary>
                    <Summary.Content>
                        {user.volunteerInfo?.[0]?.travelRestrictions
                            ? 'Yes'
                            : 'No'}
                    </Summary.Content>
                    <Summary.Label>Travel Restrictions</Summary.Label>
                </Summary>
                <Summary>
                    <Summary.Content>
                        {missionInfo?.passportCountry?.name}
                    </Summary.Content>
                    <Summary.Label>Passport Country</Summary.Label>
                </Summary>
                <Summary>
                    <Summary.Content>
                        {missionInfo?.yearsOfExperience}
                    </Summary.Content>
                    <Summary.Label>Years of Mission Experience</Summary.Label>
                </Summary>
                {missionInfo?.pastMissionDescription && (
                    <Summary>
                        <Summary.Content>
                            {missionInfo?.pastMissionDescription}
                        </Summary.Content>
                        <Summary.Label>Past Mission Description</Summary.Label>
                    </Summary>
                )}
                <Summary>
                    <Summary.Content>
                        {(missionInfo?.languages || skillInfo?.languages)
                            ?.map((lang) => lang.name)
                            .join(', ')}
                    </Summary.Content>
                    <Summary.Label>Languages You Speak</Summary.Label>
                </Summary>
                <Summary>
                    <Summary.Content>
                        {missionInfo?.healthConcerns ? 'Yes' : 'No'}
                    </Summary.Content>
                    <Summary.Label>Health Concerns</Summary.Label>
                </Summary>
                {missionInfo?.healthConcerns && (
                    <Summary>
                        <Summary.Content>
                            {missionInfo?.healthConcernDescription}
                        </Summary.Content>
                        <Summary.Label>
                            Health Concern Description
                        </Summary.Label>
                    </Summary>
                )}
                {skillInfo?.enabled && <>
                    <div className="mb-4">
                        <span
                            style={{ color: colors.primary.solid }}
                            className="font-semibold"
                        >
                            Skill Information
                        </span>
                        <hr />
                    </div>
                    <Summary>
                        <Summary.Content>
                            {skillInfo?.educationalBackground}
                        </Summary.Content>
                        <Summary.Label>Educational Background</Summary.Label>
                    </Summary>
                    <Summary>
                        <Summary.Content>
                            {skillInfo?.skills?.map((s) => s.name).join(', ')}
                        </Summary.Content>
                        <Summary.Label>Skills to Volunteer</Summary.Label>
                    </Summary>
                    {skillInfo?.skills?.map((s, index) => {
                        return (
                            <div className="flex" key={index}>
                                <Summary className="w-1/3">
                                    <Summary.Content>{s.rateType}</Summary.Content>
                                    <Summary.Label>{s.name}</Summary.Label>
                                </Summary>
                                {s.rateType !== RateType.FREE && (
                                    <Summary className="w-1/3">
                                        <Summary.Content>
                                            {s.amountPerHour}/hr
                                        </Summary.Content>
                                        <Summary.Label>
                                            Amount Per Hour
                                        </Summary.Label>
                                    </Summary>
                                )}
                            </div>
                        )
                    })}
                </>}
            </SteppedPage.Content>
        </SteppedPage>
    )
}

interface SummaryProps {
    className?: string
}

class Summary extends React.Component<SummaryProps, any> {
    static Label = ({ children }: any) => (
        <label className="text-sm font-bold text-gray-600 uppercase">
            {children}
        </label>
    )
    static Content = ({ children }: any) => (
        <label className="text-lg text-gray-600 capitalize font-extralight">
            {children}
        </label>
    )

    render() {
        const { children, className } = this.props
        return (
            <div className={`flex flex-col gap-1 mb-8 ${className}`}>
                {children}
            </div>
        )
    }
}
