import React, { useContext, useState } from 'react'
import AutocompleteTextField from '../../components/AutocompleteTextField'
import Dropdown, { DropdownSelectOption } from '../../components/Dropdown'
import { FieldSet } from '../../components/FieldSet'
import { HighlightCardButton } from '../../components/HighlightCardButton'
import { InputField, InputFieldType } from '../../components/InputField'
import Pill from '../../components/Pill'
import PopupLikeFieldWrapper from '../../components/PopupLikeFieldWrapper'
import { EducationType } from '../../enums/EducationType'
import { VolunteerType } from '../../enums/VolunteerType'
import { useRedirectToPage } from '../../hooks/useRedirectToPage'
import { TocContext } from '../../models/Context'
import { Skill, SkillVolunteer } from '../../models/Volunteer'
import { RateType } from '../../enums/RateType'
import { languages } from '../../services/LanguageService'
import { kRoutes } from '../../utils/Routes'
import { skills } from '../../services/SkillService'

const backgroundList: DropdownSelectOption<string>[] = Object.keys(
    EducationType
).map((et) => {
    const background = (EducationType as any)[et]
    return { title: background, value: background }
})

// step for this screen.
const pageRoute = kRoutes.MissionAndSkill

export default function SkillSubpage() {
    const { appData } = useContext(TocContext)
    const [user, , , step, , onUserChanged] = appData // eslint-disable-line no-unused-vars
    useRedirectToPage(pageRoute, step)

    const skillInfo: SkillVolunteer | undefined = user.volunteerInfo?.[1]
        ? user.volunteerInfo?.[1]
        : {
              type: VolunteerType.SKILL,
          }

    const [activeSkill, setActiveSkill] = useState<Skill | undefined>()

    function updateActiveSkillRateType(rateType: RateType) {
        const _activeSkill: Skill = {
            ...activeSkill!,
            rateType,
        }
        setActiveSkill(_activeSkill)

        updateSkillInfo({
            skills: selectedSkills?.map((sk) =>
                sk.name === _activeSkill?.name ? _activeSkill : sk
            ),
        })
    }

    function updateActiveSkillRate(rate: number) {
        const _activeSkill: Skill = {
            ...activeSkill!,
            amountPerHour: rate,
        }
        setActiveSkill(_activeSkill)

        updateSkillInfo({
            skills: selectedSkills?.map((sk) =>
                sk.name === _activeSkill?.name ? _activeSkill : sk
            ),
        })
    }

    function updateActiveSkillInfo(info: string) {
        const _activeSkill: Skill = {
            ...activeSkill!,
            info: info,
        }
        setActiveSkill(_activeSkill)

        updateSkillInfo({
            skills: selectedSkills?.map((sk) =>
                sk.info === _activeSkill?.name ? _activeSkill : sk
            ),
        })
    }

    const { languages: selectedLanguages, skills: selectedSkills } = skillInfo

    const updateSkillInfo = (updateObj: Partial<SkillVolunteer>) => {
        const missionInfo = user.volunteerInfo?.[0]
        onUserChanged({
            volunteerInfo: [missionInfo, { ...skillInfo, ...updateObj }],
        })
    }

    return (
        <div className="animation__fade_in">
            <FieldSet>
                <FieldSet.Label>
                    What is your educational background?
                </FieldSet.Label>
                <FieldSet.Field>
                    <Dropdown
                        className="capitalize"
                        options={backgroundList}
                        defaultSelection={{
                            value: skillInfo.educationalBackground,
                            title: '',
                        }}
                        onSelectionChange={(background) =>
                            updateSkillInfo({
                                educationalBackground: background.title,
                            })
                        }
                    />
                </FieldSet.Field>
            </FieldSet>
            <FieldSet hideBottomMargin={true}>
                <FieldSet.Label>
                    What skills do you want to volunteer?
                </FieldSet.Label>
                <FieldSet.Field>
                    <div className="p-4 border border-gray-200 rounded">
                        <AutocompleteTextField
                            placeholder="Type, to add skills"
                            options={skills.map((sk) => sk.name)}
                            className={`${selectedSkills?.length && 'mb-4'}`}
                            onOptionSelected={(index) => {
                                const selectedSkill = skills[index]

                                if (
                                    selectedSkills?.some(
                                        (sk) => sk.name === selectedSkill.name
                                    )
                                ) {
                                    return
                                }

                                const skillSet = Array.from(
                                    new Set([
                                        ...(selectedSkills || []),
                                        selectedSkill,
                                    ])
                                )

                                setActiveSkill(selectedSkill)

                                updateSkillInfo({
                                    skills: skillSet,
                                })
                            }}
                        />
                        {!!selectedSkills?.length && (
                            <div className="flex flex-wrap gap-2">
                                {selectedSkills.map((sk, index) => (
                                    <Pill
                                        className={`transition-shadow duration-150 ${
                                            sk.name === activeSkill?.name
                                                ? 'shadow-outline'
                                                : ''
                                        }`}
                                        key={`sl${index}`}
                                        onClick={() => setActiveSkill(sk)}
                                        onRemove={() =>
                                            updateSkillInfo({
                                                skills: selectedSkills.filter(
                                                    (a) => a !== sk
                                                ),
                                            })
                                        }
                                    >
                                        {sk?.name}
                                    </Pill>
                                ))}
                            </div>
                        )}
                    </div>
                </FieldSet.Field>
            </FieldSet>

            {activeSkill && (
                <PopupLikeFieldWrapper
                    className="gap-6 animation__fade_in"
                    title={
                        <div className="flex flex-row justify-between">
                            <span>
                                How are you offering{' '}
                                <span className="italic">
                                    {activeSkill.name}?
                                </span>
                            </span>
                            <span
                                title="Close"
                                className="cursor-pointer"
                                onClick={() => setActiveSkill(undefined)}
                            >
                                &times;
                            </span>
                        </div>
                    }
                    body={
                        <FieldSet className="gap-6" hideBottomMargin={true}>
                            <HighlightCardButton
                                className="transition duration-300 ease-in-out"
                                icon={
                                    <svg
                                        width={62}
                                        /*Free - at no cost to the ministry*/
                                        height={62}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M25.332 50.664c1.642 0 3.248-.156 4.803-.454a17.734 17.734 0 01-.75-5.119c0-1.857.286-3.648.816-5.33-.73.189-1.502.334-2.31.431v2.023a2.37 2.37 0 01-2.373 2.37 2.37 2.37 0 01-2.372-2.37V40.23c-6.264-.648-10.129-4.093-10.48-9.052h7.428c.26 1.722 1.324 2.904 3.052 3.443v-6.44l-1.593-.361c-5.395-1.224-8.183-4.01-8.183-8.2 0-4.814 3.785-8.153 9.776-8.81v-2.36a2.37 2.37 0 012.372-2.37 2.37 2.37 0 012.372 2.37v2.401c5.675.758 9.313 4.13 9.404 8.925h-7.036c-.103-1.49-.961-2.62-2.368-3.218v6.312l2.055.446c5.204 1.176 7.579 3.096 7.988 6.605a17.65 17.65 0 019.185-2.561c1.176 0 2.326.114 3.438.333.072-.777.108-1.564.108-2.36C50.664 11.342 39.323 0 25.332 0 11.342 0 0 11.342 0 25.332s11.342 25.332 25.332 25.332zm-2.186-34.29c-1.51.469-2.427 1.465-2.427 2.776 0 1.142.796 1.986 2.427 2.584v-5.36zm4.744 12.93v5.362c1.62-.455 2.55-1.431 2.55-2.76 0-1.266-.72-2.02-2.55-2.603zm11.018 7.578c3.891-3.891 9.933-4.32 14.298-1.289L37.62 51.18c-3.032-4.366-2.603-10.407 1.288-14.298zm17.127 1.54L40.448 54.008c4.366 3.032 10.408 2.603 14.298-1.288 3.891-3.891 4.32-9.933 1.289-14.298zm1.54 17.126c-5.936 5.936-15.56 5.936-21.495 0-5.936-5.936-5.936-15.56 0-21.495 5.936-5.936 15.56-5.936 21.495 0 5.936 5.936 5.936 15.56 0 21.495z"
                                            fill={
                                                activeSkill.rateType ===
                                                RateType.FREE
                                                    ? '#CE6849'
                                                    : '#4F4F4F'
                                            }
                                        />
                                    </svg>
                                }
                                title="Free"
                                body={
                                    <>
                                        You will charge{' '}
                                        <span className="underline">
                                            no fee
                                        </span>{' '}
                                        for your services
                                    </>
                                }
                                selected={
                                    activeSkill.rateType === RateType.FREE
                                }
                                onClick={() =>
                                    updateActiveSkillRateType(RateType.FREE)
                                }
                            />
                            <HighlightCardButton
                                className="transition duration-300 ease-in-out"
                                icon={
                                    <svg
                                        width={62}
                                        /*Paid - offer your skills for a price*/
                                        height={62}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M31 62c17.12 0 31-13.88 31-31C62 13.88 48.12 0 31 0 13.88 0 0 13.88 0 31c0 17.12 13.88 31 31 31zm.227-54.56a2.901 2.901 0 00-2.902 2.9v2.886c-7.332.805-11.964 4.891-11.964 10.782 0 5.129 3.413 8.537 10.014 10.035l1.95.44v7.883c-2.115-.66-3.418-2.106-3.735-4.214H15.5c.43 6.069 5.16 10.285 12.825 11.078v2.43c0 1.602 1.3 2.9 2.902 2.9a2.901 2.901 0 002.903-2.9v-2.475c7.47-.902 12.37-5.108 12.37-11.128 0-5.225-2.807-7.933-9.855-9.526l-2.515-.546v-7.724c1.721.733 2.772 2.115 2.898 3.938h8.61c-.111-5.867-4.564-9.994-11.508-10.92v-2.94c0-1.6-1.3-2.899-2.903-2.899zm-2.902 12.597c-1.848.574-2.97 1.794-2.97 3.397 0 1.398.974 2.43 2.97 3.163v-6.56zM34.13 35.86v6.562c1.982-.557 3.121-1.751 3.121-3.378 0-1.549-.882-2.472-3.12-3.184z"
                                            fill={
                                                activeSkill.rateType ===
                                                RateType.PAID
                                                    ? '#CE6849'
                                                    : '#4F4F4F'
                                            }
                                        />
                                    </svg>
                                }
                                title="Paid"
                                body="Your services are not free"
                                selected={
                                    activeSkill.rateType === RateType.PAID
                                }
                                onClick={() =>
                                    updateActiveSkillRateType(RateType.PAID)
                                }
                            />
                            {activeSkill.rateType === RateType.PAID && (
                                <FieldSet hideBottomMargin={true}>
                                    <FieldSet.Label>
                                        Amount per hour ($)
                                    </FieldSet.Label>
                                    <FieldSet.Field>
                                        <InputField
                                            type={InputFieldType.NUMBER}
                                            step={0.01}
                                            prefix="$"
                                            value={activeSkill.amountPerHour}
                                            onInput={(e) =>
                                                updateActiveSkillRate(
                                                    parseFloat(
                                                        e.currentTarget.value
                                                    )
                                                )
                                            }
                                        />
                                    </FieldSet.Field>
                                </FieldSet>
                            )}
                            <HighlightCardButton
                                className="transition duration-300 ease-in-out"
                                icon={
                                    <svg
                                        width={62}
                                        /*Paid - at discount*/
                                        height={62}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M31 62c17.12 0 31-13.88 31-31C62 13.88 48.12 0 31 0 13.88 0 0 13.88 0 31c0 17.12 13.88 31 31 31zm.227-54.56a2.901 2.901 0 00-2.902 2.9v2.886c-7.332.805-11.964 4.891-11.964 10.782 0 5.129 3.413 8.537 10.014 10.035l1.95.44v7.883c-2.115-.66-3.418-2.106-3.735-4.214H15.5c.43 6.069 5.16 10.285 12.825 11.078v2.43c0 1.602 1.3 2.9 2.902 2.9a2.901 2.901 0 002.903-2.9v-2.475c7.47-.902 12.37-5.108 12.37-11.128 0-5.225-2.807-7.933-9.855-9.526l-2.515-.546v-7.724c1.721.733 2.772 2.115 2.898 3.938h8.61c-.111-5.867-4.564-9.994-11.508-10.92v-2.94c0-1.6-1.3-2.899-2.903-2.899zm-2.902 12.597c-1.848.574-2.97 1.794-2.97 3.397 0 1.398.974 2.43 2.97 3.163v-6.56zM34.13 35.86v6.562c1.982-.557 3.121-1.751 3.121-3.378 0-1.549-.882-2.472-3.12-3.184z"
                                            fill={
                                                activeSkill.rateType ===
                                                RateType.DISCOUNTED
                                                    ? '#CE6849'
                                                    : '#4F4F4F'
                                            }
                                        />
                                    </svg>
                                }
                                title="Paid at reduced rate"
                                body={
                                    <>
                                        Your services will come at a{' '}
                                        <span className="underline">
                                            discount
                                        </span>
                                    </>
                                }
                                selected={
                                    activeSkill.rateType === RateType.DISCOUNTED
                                }
                                onClick={() =>
                                    updateActiveSkillRateType(
                                        RateType.DISCOUNTED
                                    )
                                }
                            />
                            {activeSkill.rateType === RateType.DISCOUNTED && (
                                <FieldSet hideBottomMargin={true}>
                                    <FieldSet.Label>
                                        Amount per hour ($)
                                    </FieldSet.Label>
                                    <FieldSet.Field>
                                        <InputField
                                            type={InputFieldType.NUMBER}
                                            step={0.01}
                                            prefix="$"
                                            value={
                                                activeSkill.amountPerHour || 0.0
                                            }
                                            onInput={(e) =>
                                                updateActiveSkillRate(
                                                    parseFloat(
                                                        e.currentTarget.value
                                                    )
                                                )
                                            }
                                        />
                                    </FieldSet.Field>
                                </FieldSet>
                            )}
                            {activeSkill.name === "Other (explain)" && (  
                                <FieldSet hideBottomMargin={true}>
                                <FieldSet.Label>
                                    Please provide details about this skill below
                                </FieldSet.Label>
                                <FieldSet.Field>
                                    <InputField
                                        type={InputFieldType.TEXT}
                                        value={
                                            activeSkill.info || ""
                                        }
                                        onInput={(e) =>
                                            updateActiveSkillInfo(e.currentTarget.value)
                                        }
                                    />
                                </FieldSet.Field>
                            </FieldSet>
                            )}
                        </FieldSet>
                    }
                ></PopupLikeFieldWrapper>
            )}

            <FieldSet className="mt-8">
                <FieldSet.Label>What languages do you speak?</FieldSet.Label>
                <FieldSet.Field>
                    <div className="p-4 border border-gray-200 rounded">
                        <AutocompleteTextField
                            placeholder="Type, to add languages"
                            options={languages.map((ln) => ln.name)}
                            className={`${selectedLanguages?.length && 'mb-4'}`}
                            onOptionSelected={(index) => {
                                const selectedLanguage = languages[index]
                                const languageSet = Array.from(
                                    new Set([
                                        ...(selectedLanguages || []),
                                        selectedLanguage,
                                    ])
                                )

                                updateSkillInfo({
                                    languages: languageSet.map(
                                        (ln) =>
                                            languages.find(
                                                (g) => g.code === ln.code
                                            )!
                                    ),
                                })
                            }}
                        />
                        {!!selectedLanguages?.length && (
                            <div className="flex flex-wrap gap-2">
                                {selectedLanguages.map((sl, index) => (
                                    <Pill
                                        className="transition-opacity duration-150"
                                        key={`sl${index}`}
                                        onRemove={() =>
                                            updateSkillInfo({
                                                languages:
                                                    selectedLanguages.filter(
                                                        (a) => a !== sl
                                                    ),
                                            })
                                        }
                                    >
                                        {sl?.name}
                                    </Pill>
                                ))}
                            </div>
                        )}
                    </div>
                </FieldSet.Field>
            </FieldSet>
        </div>
    )
}
