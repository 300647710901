import React from 'react'
import { StyleSheet } from '../interfaces/StyleSheet'

interface Props {
    title?: JSX.Element | string
    body: JSX.Element | string
    className?: string
    showNotch?: boolean
}

export default function PopupLikeFieldWrapper({
    title,
    body,
    className,
    showNotch = true,
}: Props) {
    return (
        <div
            className={`relative border bg-gray-200 shadow-sm rounded ${className}`}
        >
            {showNotch && (
                <span
                    className="absolute inline-block w-0 h-0 border-transparent border-8"
                    style={styles.notch}
                />
            )}
            {!!title && (
                <div
                    className="px-6 py-3 border-b bg-gray-200 font-semibold text-base text-gray-800 text-left"
                    style={styles.header}
                >
                    {title}
                </div>
            )}
            <div
                className="px-6 py-4 bg-gray-100 text-md text-gray-600"
                style={styles.body}
            >
                {body}
            </div>
        </div>
    )
}

const styles: StyleSheet = {
    notch: {
        borderBottomColor: 'inherit',
        top: -16,
        left: 16,
    },
}
