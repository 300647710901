import React, { FormEvent, useRef, useState } from 'react'
import { InputField, InputFieldType } from './InputField'

export interface AutocompleteOption<T> {
    title: string
    value?: T
}

interface Props {
    options: string[]
    className?: string
    placeholder?: string
    onOptionSelected: (index: number) => void
}

export default function AutocompleteTextField({
    options,
    className,
    placeholder,
    onOptionSelected,
}: Props) {
    const [showMenu, setShowMenu] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const [keyword, setKeyword] = useState('')
    const matchingOptions = options.filter((op) =>
        op.toLowerCase().includes(keyword.toLowerCase())
    )

    const showAutocomplete = (e: FormEvent<HTMLInputElement>) => {
        const keyword = e.currentTarget.value
        setKeyword(keyword)
        setShowMenu(!!keyword)
    }

    const onMenuItemClicked = (index: number) => {
        setShowMenu(false)
        setKeyword('')

        if (inputRef.current) {
            inputRef.current.focus()
        }
        onOptionSelected(index)
    }

    return (
        <div className={`relative ${className}`}>
            <InputField
                type={InputFieldType.TEXT}
                className="w-full"
                placeholder={placeholder}
                value={keyword}
                onChange={showAutocomplete}
                onBlur={() =>
                    keyword && setTimeout(() => setShowMenu(false), 250)
                }
                onFocus={() => setShowMenu(!!keyword)}
                ref={inputRef}
            />
            <div
                role="menu"
                aria-roledescription="menu"
                className="rounded-lg inline-block border absolute left-0 bg-white z-50 overflow-y-scroll hover:border-orange-300 shadow-md outline-none"
                tabIndex={-1}
                style={{ top: 42, maxHeight: 180 }}
            >
                {keyword &&
                    showMenu &&
                    matchingOptions.length !== 0 &&
                    matchingOptions.map((opt, index) => (
                        <div
                            role="menuitem"
                            aria-label={opt}
                            tabIndex={-1}
                            className="hover:bg-gray-300 px-5 py-2 cursor-pointer transition-colors duration-300 outline-none"
                            key={index}
                            onClick={() =>
                                onMenuItemClicked(options.indexOf(opt))
                            }
                        >
                            {opt}
                        </div>
                    ))}
            </div>
        </div>
    )
}
