import React, { FormEvent, useEffect } from 'react'
import '../styles/components/FormField.css'

export enum InputFieldType {
    TEXT = 'text',
    NUMBER = 'number',
    TIME = 'time',
}

interface Props {
    id?: string
    name?: string
    type: InputFieldType
    step?: string | number | undefined
    prefix?: string | undefined
    defaultValue?: string | number | readonly string[] | undefined
    value?: string | number | undefined
    disabled?: boolean
    placeholder?: string
    title?: string
    className?: string
    invalid?: boolean
    onInput?: (e: FormEvent<HTMLInputElement>) => void
    onChange?: (e: FormEvent<HTMLInputElement>) => void
    onBlur?: (e: FormEvent<HTMLInputElement>) => void
    onFocus?: (e: FormEvent<HTMLInputElement>) => void
}

export const InputField = React.forwardRef<HTMLInputElement, Props>(
    (props, ref) => {
        const {
            id,
            name,
            type,
            step,
            prefix,
            defaultValue,
            value,
            disabled,
            placeholder,
            title,
            className,
            invalid,
            onInput,
            onChange,
            onBlur,
            onFocus,
        } = props

        useEffect(() => {
            return () => {}
        }, [value])

        return (
            <div
                className={`app__input flex relative justify-between border bg-white rounded-sm overflow-hidden hover:shadow-xs transition__box-shadow ${
                    invalid ? 'border-red-500' : ''
                }`}
            >
                {prefix && (
                    <span className="flex items-center justify-center w-10 font-semibold border-r text-md">
                        {prefix}
                    </span>
                )}
                <input
                    type={type}
                    step={step}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    title={title}
                    onInput={onInput}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    ref={ref}
                    value={value}
                    className={`app__input px-3 py-2 border-none text-gray-800 w-full focus:shadow-none ${className}`}
                />
            </div>
        )
    }
)
