import React from 'react'
import { colors } from './styles/Colors'

interface Props {
    content?: JSX.Element
    style?: React.CSSProperties
    className?: string
}

export default function HighlightedBox(props: Props) {
    const selectedWrapperStyle = {
        boxShadow: `0 0 2px 2px ${colors.primary.solid}`,
    }

    return (
        <div
            style={{ ...selectedWrapperStyle, ...props.style }}
            className={`px-8 py-6 bg-white text-base text-gray-600 font-light rounded-sm ${props.className}`}
        >
            {props.content}
        </div>
    )
}
