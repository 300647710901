import React, { useRef } from 'react'
import { StyleSheet } from '../interfaces/StyleSheet'
import { CheckboxType } from '../enums/CheckboxType'
import { colors } from './styles/Colors'
import '../styles/tailwind.output.css'
import '../styles/components/FormField.css'
import { Switch } from '@headlessui/react'

interface CheckboxProps<T = undefined> {
    type?: CheckboxType
    selected?: boolean
    style?: StyleSheet
    children?: JSX.Element | string
    onSelectionChange?: (selected: boolean) => void
    value?: T
}

function Checkbox<T = undefined>({
    type,
    selected,
    style,
    children,
    value,
    onSelectionChange,
}: CheckboxProps<T>) {
    const elementIdRef = useRef((Math.random() * Date.now()).toString())

    const checkboxMarkup = (
        <div
            className="w-1/3 border-b-2 border-r-2 border-transparent border-solid"
            style={
                selected ? styles.checkmarkSelected : styles.checkmarkUnselected
            }
        >
            <input
                type="checkbox"
                defaultChecked={selected}
                className="hidden"
            />
        </div>
    )

    const radioMarkup = (
        <div className="w-1 h-1 rounded-full" style={styles.radio}>
            <input type="radio" defaultChecked={selected} className="hidden" />
        </div>
    )

    const onClick = () => {
        if (type === CheckboxType.radio && selected) {
            return
        }
        onSelectionChange && onSelectionChange(!selected)
    }

    return (
        <Switch checked={!!selected} onChange={onClick} className="flex items-center gap-2 p-1">
            <div
                id={elementIdRef.current}
                className="inline-block w-4 h-4 border-solid rounded-full hover:shadow transition__box-shadow"
                style={{
                    ...style,
                    ...(selected
                        ? styles.wrapperSelected
                        : styles.wrapperUnselected),
                }}
            >
                <div className="flex items-center justify-center h-full">
                    {type !== CheckboxType.radio ? checkboxMarkup : radioMarkup}
                </div>
            </div>
            {children && (
                <label
                    className="font-light text-left text-gray-700"
                    htmlFor={elementIdRef.current}
                >
                    {children}
                </label>
            )}
        </Switch>
    )
}

// Checkbox.propTypes = {
//     type: PropTypes.oneOf([CheckboxType.check, CheckboxType.radio]),
//     selected: PropTypes.bool,
//     onSelectionChange: PropTypes.func,
// }

const baseStyles: StyleSheet = {
    wrapper: {
        borderWidth: 1,
    },
    checkmark: {
        marginTop: -2,
        height: '55%',
        transform: 'rotateZ(45deg)',
    },
}

const styles: StyleSheet = {
    wrapperSelected: {
        ...baseStyles.wrapper,
        borderColor: colors.border,
        backgroundColor: colors.primary.solid,
    },
    wrapperUnselected: {
        ...baseStyles.wrapper,
        borderColor: colors.base.lightGray,
        backgroundColor: colors.base.white,
    },
    checkmarkSelected: {
        ...baseStyles.checkmark,
        borderColor: colors.base.white,
        // borderBottomRightRadius: 4,
    },
    checkmarkUnselected: {
        ...baseStyles.checkmark,
    },
    radio: {
        backgroundColor: colors.base.white,
    },
}

export default Checkbox
