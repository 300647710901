export function useLocalStorage<T>(storageKey: string) {
    const update = (object: T): void => {
        if (!!object) {
            localStorage.setItem(storageKey, JSON.stringify(object))
        }
    }

    const get = (): T | undefined => {
        const value = localStorage.getItem(storageKey)

        if (!!value) return JSON.parse(value)
    }

    const isEmpty = (): boolean => get() == null

    const clear = (): void => localStorage.removeItem(storageKey)

    return {
        update,
        get,
        get isEmpty() {
            return isEmpty()
        },
        clear,
    }
}
