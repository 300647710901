import React from 'react'
import { StyleSheet } from '../interfaces/StyleSheet'
import '../styles/components/FormField.css'

export interface DropdownSelectOption<T> {
    title: string
    value?: T
}

interface Props {
    id?: string
    name?: string
    defaultSelection?: DropdownSelectOption<any>
    className?: string
    invalid?: boolean
    options: DropdownSelectOption<any>[]
    onSelectionChange?: (o: DropdownSelectOption<any>) => void
}

export default function Dropdown({
    id,
    name,
    defaultSelection,
    className,
    invalid,
    options,
    onSelectionChange,
}: Props) {
    return (
        <select
            className={`${className} app__select transition__box-shadow w-full p-3 border outline-orange text-gray-600 shadow ${
                invalid ? 'border-red-500' : ''
            }`}
            style={styles.select}
            name={name}
            id={id}
            defaultValue={defaultSelection?.value}
            onChange={(e) =>
                onSelectionChange &&
                onSelectionChange(options[e.target.selectedIndex])
            }
        >
            {(options || []).map((option, index) => (
                <option
                    key={index}
                    value={option.value || option.title}
                >
                    {option.title}
                </option>
            ))}
        </select>
    )
}

const styles: StyleSheet = {
    select: {
        outlineColor: 'orange',
    },
}
