import { Skill } from '../models/Skill'
import { RateType } from '../enums/RateType'

const skills: Skill[] = [
    {
        name: 'Legal',
        rateType: RateType.FREE,
    },
    { name: 'Teaching', rateType: RateType.FREE },
    { name: 'Software Development', rateType: RateType.FREE },
    { name: 'Software Testing', rateType: RateType.FREE },
    { name: 'Legal', rateType: RateType.FREE },
    { name: 'Accounting', rateType: RateType.FREE },
    { name: 'Investing', rateType: RateType.FREE },
    { name: 'Language Interpreter', rateType: RateType.FREE },
    { name: 'Media', rateType: RateType.FREE },
    { name: 'Filming', rateType: RateType.FREE },
    { name: 'Film Editing', rateType: RateType.FREE },
    { name: 'A/V', rateType: RateType.FREE },
    { name: 'Sound Editing', rateType: RateType.FREE },
    { name: 'Medical', rateType: RateType.FREE },
    { name: 'Other (explain)', rateType: RateType.FREE },
]

export { skills }
