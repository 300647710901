import React, { useContext } from 'react'
import AutocompleteTextField, { AutocompleteOption } from '../../components/AutocompleteTextField'
import Checkbox from '../../components/Checkbox'
import Dropdown, { DropdownSelectOption } from '../../components/Dropdown'
import { FieldSet } from '../../components/FieldSet'
import HighlightedBox from '../../components/HighlightedBox'
import Pill from '../../components/Pill'
import PopupLikeFieldWrapper from '../../components/PopupLikeFieldWrapper'
import { colors } from '../../components/styles/Colors'
import { TextArea } from '../../components/TextArea'
import { VolunteerType } from '../../enums/VolunteerType'
import { useRedirectToPage } from '../../hooks/useRedirectToPage'
import { TocContext } from '../../models/Context'
import { MissionVolunteer } from '../../models/Volunteer'
import { countries } from '../../services/CountryService'
import { languages } from '../../services/LanguageService'
import { kRoutes } from '../../utils/Routes'

// const countryList: DropdownSelectOption<String>[] = countries.map((c) => ({
//     title: c.name,
//     value: c.code || c.name,
// }))

// import { AutocompleteOption } from '../../components/AutocompleteTextField'

const countryList: AutocompleteOption<String>[] = countries.map((c) => ({
    title: c.name,
    value: c.code || c.name,
}))

const travelExperienceYears: DropdownSelectOption<number>[] = Array.of(
    0,
    1,
    2,
    3,
    4
).map((num) => ({
    value: num,
    title: `${num} year${num !== 1 ? 's' : ''}${num === 4 ? ' or more' : ''}`,
}))

// step for this screen.
const pageRoute = kRoutes.MissionAndSkill

export default function MissionSubpage() {
    const { appData } = useContext(TocContext)
    const [user, , , step, , onUserChanged] = appData // eslint-disable-line no-unused-vars
    useRedirectToPage(pageRoute, step)

    const missionInfo: MissionVolunteer | undefined = user.volunteerInfo?.[0]
        ? user.volunteerInfo?.[0]
        : {
              type: VolunteerType.MISSION,
          }

    const { languages: selectedLanguages } = missionInfo

    const updateMissionInfo = (updateObj: Partial<MissionVolunteer>) => {
        const skillInfo = user.volunteerInfo?.[1]
        onUserChanged({
            volunteerInfo: [{ ...missionInfo, ...updateObj }, skillInfo],
        })
    }

    return (
        <div className="animation__fade_in">
            <FieldSet>
                <FieldSet.Label>
                    Do you have any travel restrictions?
                </FieldSet.Label>
                <FieldSet.Field>
                    <div className="flex gap-8">
                        <Checkbox
                            selected={missionInfo.travelRestrictions}
                            onSelectionChange={() =>
                                updateMissionInfo({ travelRestrictions: true })
                            }
                        >
                            Yes
                        </Checkbox>
                        <Checkbox
                            selected={missionInfo.travelRestrictions != null && !missionInfo.travelRestrictions}
                            onSelectionChange={() =>
                                updateMissionInfo({
                                    travelRestrictions: false,
                                })
                            }
                        >
                            No
                        </Checkbox>
                    </div>
                </FieldSet.Field>
            </FieldSet>
            {missionInfo.travelRestrictions && (
                <HighlightedBox
                    className="mb-8 bg-blue-100 animation__fade_in"
                    style={{
                        backgroundColor: '#F9FCFF',
                        boxShadow: `0 0 2px 2px ${colors.primary.opacity(
                            '45%'
                        )}`,
                    }}
                    content={
                        <div className="flex flex-col">
                            <div className="flex items-center gap-4 mb-4">
                                <h4
                                    className="font-semibold"
                                    style={{
                                        color: colors.primary.solid,
                                    }}
                                >
                                    Important Note
                                </h4>
                                <svg
                                    width="18"
                                    height="19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.947 2.538L16 10.168l-6.55 6.888-7.064-7.62"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M2.386 9.437L2 2.132l6.947.406"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        clipRule="evenodd"
                                        d="M7.03 6.99c.141.15.105.414-.053.58-.157.164-.399.177-.54.028-.143-.15-.13-.404.027-.569.157-.165.423-.189.565-.04v0z"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            <p>
                                Please understand that most of our missions are
                                abroad. If selected as a candidate, you may be
                                required to travel.
                            </p>
                        </div>
                    }
                />
            )}
            <FieldSet className="mb-8">
                <FieldSet.Label>
                    What country passport do you hold?
                </FieldSet.Label>
                <FieldSet.Field>
                    <Dropdown
                        options={countryList}
                        defaultSelection={{
                            value: missionInfo.passportCountry?.code,
                            title: '',
                        }}
                        onSelectionChange={(country) =>
                            updateMissionInfo({
                                passportCountry: {
                                    code: country.value,
                                    name: country.title,
                                },
                            })
                        }
                    />
                </FieldSet.Field>
            </FieldSet>
            <FieldSet className="mb-8">
                <FieldSet.Label>
                    How many years of mission experience do you have?
                </FieldSet.Label>
                <FieldSet.Field>
                    <Dropdown
                        options={travelExperienceYears}
                        onSelectionChange={(selection) =>
                            updateMissionInfo({
                                yearsOfExperience: selection.value,
                            })
                        }
                    />
                </FieldSet.Field>
            </FieldSet>
            <FieldSet className="mb-8">
                <FieldSet.Label>
                    Briefly describe a past mission you have been on
                </FieldSet.Label>
                <FieldSet.Field>
                    <TextArea
                        rows={3}
                        // placeholder={placeholder}
                        onInput={(event) => {
                            updateMissionInfo({
                                pastMissionDescription:
                                    event.currentTarget.value,
                            })
                        }}
                    />
                </FieldSet.Field>
            </FieldSet>
            <FieldSet className="mb-8">
                <FieldSet.Label>What languages do you speak?</FieldSet.Label>
                <FieldSet.Field>
                    <div className="p-4 border border-gray-200 rounded">
                        <AutocompleteTextField
                            placeholder="Type, to add languages"
                            options={languages.map((ln) => ln.name)}
                            className={`${selectedLanguages?.length && 'mb-4'}`}
                            onOptionSelected={(index) => {
                                const selectedLanguage = languages[index]
                                const languageSet = Array.from(
                                    new Set([
                                        ...(selectedLanguages || []),
                                        selectedLanguage,
                                    ])
                                )

                                updateMissionInfo({
                                    languages: languageSet.map(
                                        (ln) =>
                                            languages.find(
                                                (g) => g.code === ln.code
                                            ) as any
                                    ),
                                })
                            }}
                        />
                        {!!selectedLanguages?.length && (
                            <div className="flex flex-wrap gap-2">
                                {selectedLanguages.map((sl, index) => (
                                    <Pill
                                        className="transition-opacity duration-150"
                                        key={`sl${index}`}
                                        onRemove={() =>
                                            updateMissionInfo({
                                                languages:
                                                    selectedLanguages.filter(
                                                        (a) => a !== sl
                                                    ),
                                            })
                                        }
                                    >
                                        {sl?.name}
                                    </Pill>
                                ))}
                            </div>
                        )}
                    </div>
                </FieldSet.Field>
            </FieldSet>
            <FieldSet className="mb-8">
                <FieldSet.Label>Any health concerns?</FieldSet.Label>
                <FieldSet.Field>
                    <div className="flex gap-8">
                        <Checkbox
                            selected={missionInfo.healthConcerns}
                            onSelectionChange={() =>
                                updateMissionInfo({ healthConcerns: true })
                            }
                        >
                            Yes
                        </Checkbox>
                        <Checkbox
                            selected={missionInfo.healthConcerns != null && !missionInfo.healthConcerns}
                            onSelectionChange={() =>
                                updateMissionInfo({ healthConcerns: false })
                            }
                        >
                            No
                        </Checkbox>
                    </div>
                    {missionInfo.healthConcerns && (
                        <PopupLikeFieldWrapper
                            className="my-2 animation__fade_in"
                            title="Briefly describe the health concerns you have"
                            body={
                                <FieldSet hideBottomMargin={true}>
                                    <FieldSet.Field>
                                        <TextArea
                                            rows={2}
                                            // placeholder={placeholder}
                                            onInput={(event) =>
                                                updateMissionInfo({
                                                    healthConcernDescription:
                                                        event.currentTarget
                                                            .value,
                                                })
                                            }
                                        />
                                    </FieldSet.Field>
                                </FieldSet>
                            }
                        />
                    )}
                </FieldSet.Field>
            </FieldSet>
        </div>
    )
}
