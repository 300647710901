import { TocContext } from '../models/Context'
import React, { useContext } from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { USER_STORAGE_KEY } from '../hooks/useAppData'
import HighlightedBox from '../components/HighlightedBox'
import { colors } from '../components/styles/Colors'

export default function ThankYouPage () {
    const { appData } = useContext(TocContext)
    const [user, , , , , ] = appData // eslint-disable-line no-unused-vars
    const { clear: clearUserStorage } = useLocalStorage(USER_STORAGE_KEY)
    const username = user.fullName
    console.log(user);
    clearUserStorage();

    return (
            <div className="flex items-center justify-center h-screen">
                <div className="mx-10 md:w-3/5 xl:w-1/3">
                    <h1 className="text-5xl font-semibold text-primary">Thank you, {user?.fullName && username} {!user?.fullName && "volunteer"}!</h1>
                    <p className="mt-6 text-lg font-light text-justify text-gray-800">
                        Thank you for taking the time to fill this form, and for the possibility of joining Torch of Christ Ministries on one of our several mission outreaches, or to volunteer one or more of your skills. We appreciate you!

                        <br />
                        <br />

                        We will be in touch with you if there’s an opportunity that matches your skills and experience.
                    </p>
                    <div className="flex flex-col mt-10">
                        <div className="text-lg text-right text-gray-700 font-style-script">Torch of Christ Ministries</div>
                        <hr className="mt-2 shadow border-primary" />
                    </div>
                    <div> <p> <br /> <br /> </p></div>
                    { true && (
                <HighlightedBox
                    className="mb-8 bg-blue-100 animation__fade_in"
                    style={{
                        backgroundColor: '#F9FCFF',
                        boxShadow: `0 0 2px 2px ${colors.primary.opacity(
                            '45%'
                        )}`,
                    }}
                    content={
                        <div className="flex flex-col">
                            <div className="flex items-center gap-4 mb-4">
                                <h4
                                    className="font-semibold"
                                    style={{
                                        color: colors.primary.solid,
                                    }}
                                >
                                    Please Note
                                </h4>
                                <svg
                                    width="18"
                                    height="19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.947 2.538L16 10.168l-6.55 6.888-7.064-7.62"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M2.386 9.437L2 2.132l6.947.406"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        clipRule="evenodd"
                                        d="M7.03 6.99c.141.15.105.414-.053.58-.157.164-.399.177-.54.028-.143-.15-.13-.404.027-.569.157-.165.423-.189.565-.04v0z"
                                        stroke="#CE6849"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            <p>
                                To minmize spam, Torch of Christ Ministries may require you to verify your submission by a text/email confirmation. Only click trusted links.
                            </p>
                        </div>
                    }
                />
            )}
                </div>
            </div>
    )
}