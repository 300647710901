import { User } from '../models/User'
import Parse from 'parse'
import 'dotenv/config';

export class UserService {

    createUser(user: User): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const Volunteer = Parse.Object.extend('Volunteer')
            const volunteer = new Volunteer()
            volunteer.set('fullName', user.fullName)
            volunteer.set('gender', user.gender)
            volunteer.set('age', user.age)
            volunteer.set('country', user.country)
            volunteer.set(
                'contact',
                user.contactInfo.filter((c) => c.enabled)
            )

            const missionInfo = user.volunteerInfo?.[0]
            const skillInfo = user.volunteerInfo?.[1]
            volunteer.set(
                'volunteering',
                [missionInfo, skillInfo].filter((c) => c?.enabled)
            )

            resolve(await volunteer.save())
        })
    }
}
