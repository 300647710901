import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import './styles/App.css'
import Home from './pages/Home'
import { User } from './models/User'
import { PersonalDetails } from './pages/PersonalDetails'
import { TocContext } from './models/Context'
import { TocStore } from './models/Store'
import { MissionAndSkill } from './pages/MissionAndSkill'
import { LATEST_STEP_KEY, useAppData, USER_STORAGE_KEY } from './hooks/useAppData'
import { kRoutes } from './utils/Routes'
import SummaryPage from './pages/SummaryPage'
import { useLocalStorage } from './hooks/useLocalStorage'
import { Step } from './enums/Step'
import ThankYouPage from './pages/ThankYouPage'
import AdminHome from './pages/admin/AdminHome'
import Parse from 'parse'

const _user: User = {
    disclaimerAccepted: false,
    contactInfo: [],
}

const getStepFromNumber = (stepNum: number): Step => {
    let initialStep = Step.ONE;

    switch (stepNum) {
        case 2:
            initialStep = Step.TWO
            break;
        case 3:
            initialStep = Step.THREE
            break;
        case 4:
            initialStep = Step.FOUR
            break;
        default:
            initialStep = Step.ONE
    }
    return initialStep;
}

function init() {
    Parse.serverURL = 'https://parseapi.back4app.com' // This is your Server URL

    let appId = process.env.b4a_app_id;
    let jsKey = process.env.b4a_js_key;

    // Checking env vars due to "string | undefined" err from cflare build
    if (typeof appId === 'undefined' || typeof jsKey === 'undefined') {
        // Handle error, e.g., throw an exception or log a message
        console.log('Environment variables b4a_app_id and b4a_js_key fallback - not set in env.');
        appId = "bPKvtSGkU36TyhigIpDgahIfk7OqAcXREQObX5dl";
        jsKey = "WZRq7tuJ4ZIIUtz5AhmheT8Z7ilqnjyXcjdBkqr0";
        // Optionally, throw an error to halt execution
        // throw new Error('Missing required environment variables b4a_app_id and b4a_js_key.');
    } else {
        console.log("Success: Set env vars from runtime env");
    }

    Parse.initialize(appId, jsKey);
}

function App() {
    const { get: getUserFromStorage } = useLocalStorage<User>(USER_STORAGE_KEY)
    const { get: getLatestStepValue } = useLocalStorage<number>(LATEST_STEP_KEY)

    const storageUser = getUserFromStorage()
    const user = !!storageUser ? storageUser : _user
    const [store, setStore] = useState(new TocStore(user))
    const appData = useAppData(false, getStepFromNumber(getLatestStepValue()!), store, setStore)

    init()

    return (
        <TocContext.Provider value={{ store, appData }}>
            <Router>
                <Route path={kRoutes.Home.path} exact={true}>
                    <Home />
                </Route>
                <Route path="/admin" exact={true}>
                    <AdminHome />
                </Route>
                <Route path={kRoutes.PersonalDetails.path} exact={true}>
                    <PersonalDetails />
                </Route>
                <Route path={kRoutes.MissionAndSkill.path} exact={true}>
                    <MissionAndSkill />
                </Route>
                <Route path={kRoutes.SummaryPage.path} exact={true}>
                    <SummaryPage />
                </Route>
                <Route path={kRoutes.ThankYouPage.path} exact={true}>
                    <ThankYouPage />
                </Route>
                <Route
                    exact
                    path="/"
                    render={() => <Redirect to={kRoutes.Home.path} />}
                />
            </Router>
        </TocContext.Provider>
    )
}

export default App
