import React from 'react'
import { colors } from './styles/Colors'

export interface StepperProps {
    active: boolean
    complete: boolean
    hasHandle: boolean
    handleState: StepperHandleState
    value: number
}

export enum StepperHandleState {
    EMPTY = 0,
    HALF_FULL = 1,
    FULL = 2,
}

export function Stepper({
    active,
    complete,
    hasHandle,
    handleState,
    value,
}: StepperProps) {
    return (
        <div
            className="flex items-center justify-center stepper lg:flex-col lg:items-center"
        >
            {/* Stepper Indicator */}
            <div
                className={`step-indicator pointer-events-none flex justify-center items-center rounded-full ${
                    active ? 'border bg-white' : ''
                }`}
                style={{
                    borderColor: active ? colors.primary.solid : '',
                    padding: active ? '0.125rem' : '0',
                }}
            >
                {/* Stepper Value (circle) */}
                <div
                    className={`flex items-center justify-center w-6 h-6 bg-gray-400 rounded-full ${complete || active ? 'bg-primary' : ''}`}
                >
                    <span className="text-sm leading-none text-white">
                        {value}
                    </span>
                </div>
            </div>

            {/* Stepper Handle */}
            {hasHandle && (
                <div
                    className={`flex-grow h-1 bg-gray-400 lg:flex-auto lg:h-24 lg:w-1 step-handle ${
                        handleState === StepperHandleState.FULL ? 'bg-primary' : (handleState === StepperHandleState.HALF_FULL ? 'bg-gradient-l-to-r lg:bg-gradient-t-to-b' : '')
                    }`}
                />
            )}
        </div>
    )
}
