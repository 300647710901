import React, { CSSProperties, MouseEventHandler } from 'react'
import '../styles/components/Button.css'

interface Props {
    title?: string
    className?: string,
    style?: CSSProperties,
    disabled?: boolean
    children?: JSX.Element | string
    endSlot?: JSX.Element | string
    onClick?: MouseEventHandler
}

export default function Button(props: Props) {

    return (
        <button
            disabled={props.disabled}
            title={props.title}
            className={`flex rounded-md focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 text-sm text-white justify-between font-semibold uppercase outline-none focus:outline-none ${props.className}`}
            onClick={(event) => {
                if (!props.disabled) {
                    props.onClick && props.onClick(event)
                }
            }}
        >
            {props.children}
            {props.endSlot && (
                <span className="flex items-center h-full pl-10 pr-3 end-slot">
                    {props.endSlot}
                </span>
            )}
        </button>
    )
}
