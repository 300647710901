import { Route } from './../utils/Routes';
import { useHistory } from 'react-router'
import { Step } from '../enums/Step'
import { kRoutes } from '../utils/Routes'
import { useContext } from 'react';
import { TocContext } from '../models/Context';
import { isBasicDataComplete, isMissionDataComplete } from '../utils/Validators';
import { useLocalStorage } from './useLocalStorage';
import { LATEST_STEP_KEY } from './useAppData';

export const useRedirectToPage = (
    activeRoute: Route,
    step: Step
): void => {
    
    const history = useHistory()
    const { appData } = useContext(TocContext)
    const { update: updateLatestStep } = useLocalStorage<number>(LATEST_STEP_KEY)

    if (activeRoute.step === step) return;

    const [ user, ,,, ] = appData // removed due to cflare lint error

    const findRouteByStep = (s: Step) => Object.keys(kRoutes).map(k => kRoutes[k]).find(r => r.step === s)
    
    let foundRoute = findRouteByStep(step)

    const redirectToMostCompleteRoute = (activeStep: Step) => {
        if (foundRoute == null) {
            return history.push(kRoutes.Home.path)
        }

        switch(activeStep) {
            case Step.TWO:
                if (user.disclaimerAccepted) {
                    // setStep(foundRoute)
                    history.push(foundRoute.path)
                } else {
                    foundRoute = findRouteByStep(Step.ONE)!
                    redirectToMostCompleteRoute(Step.ONE)
                }
                break;
            case Step.THREE:
                if (isBasicDataComplete(user)) {
                    // setStep(foundRoute)
                    history.push(foundRoute.path)
                } else {
                    foundRoute = findRouteByStep(Step.TWO)!
                    redirectToMostCompleteRoute(Step.TWO)
                }
                break;
            case Step.FOUR:
                if (isBasicDataComplete(user) && isMissionDataComplete(user)) {
                    // setStep(foundRoute)
                    history.push(foundRoute.path)
                } else {
                    foundRoute = findRouteByStep(Step.THREE)!
                    redirectToMostCompleteRoute(Step.THREE)
                }
                break;
            case Step.FIVE:
                history.push(foundRoute.path);
                break
            default:
                history.push(kRoutes.Home.path)
                break;
        }
    }

    
    if (foundRoute !=  null) {
        redirectToMostCompleteRoute(step);

        updateLatestStep(foundRoute.step)
    }
}
