import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import Fuse from "fuse.js";

const AdminHome = () => {
  const [volunteers, setVolunteers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [filteredVolunteers, setFilteredVolunteers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [fuse, setFuse] = useState(null);

  useEffect(() => {
    const currentUser = Parse.User.current();
    if (currentUser && currentUser.get('isAdmin')) {
      setIsAdmin(true);
      fetchVolunteers();
    }
  }, [isAdmin]);

  useEffect(() => {
    setFuse(new Fuse(volunteers, {
      keys: ["fullName", "gender", "age", "country", "contact", "volunteering"]
    }));
  }, [volunteers]);

  const fetchVolunteers = async () => {
    try {
      const Volunteer = Parse.Object.extend('Volunteer');
      const query = new Parse.Query(Volunteer);
      const results = await query.find();
      setVolunteers(results.map((volunteer) => volunteer.toJSON()));
    } catch (error) {
      console.error('Error fetching volunteers:', error);
    }
  };

  const handleLogin = async () => {
    try {
      const user = await Parse.User.logIn(username, password);
      if (user.get('isAdmin')) {
        setIsAdmin(true);
        fetchVolunteers();
      } else {
        alert('You are not authorized to access this page.');
      }
    } catch (error) {
      alert('Login failed. Please check your credentials.');
    }
  };

  useEffect(() => {
    if (fuse && searchQuery.length > 0) {
      const results = fuse.search(searchQuery);
      setFilteredVolunteers(results.map((result) => result.item));
    } else {
      setFilteredVolunteers(volunteers);
    }
  }, [searchQuery, volunteers, fuse]);

  return (
    <div className="flex items-center justify-center h-screen flex-col">
      {isAdmin ? (
        <>
          <h1 className="text-5xl font-semibold text-primary mb-6 mt-1">
            Admin Dashboard
          </h1>
          <input 
            type="text" 
            className="px-4 py-2 mb-4 border rounded" 
            placeholder="Search..." 
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="mx-10 md:w-3/5 xl:w-1/3 overflow-y-auto max-h-[calc(100vh-200px)]">
            {filteredVolunteers.map((volunteer, index) => (
              <div key={index} className="border-b p-2">
                <p>{volunteer.fullName}</p>
                <p>{volunteer.gender}</p>
                <p>{volunteer.age}</p>
                {/* ... Add more common fields ... */}
  
                {volunteer.volunteering.map((volunteerInfo, i) => (
                  volunteerInfo.enabled ? (
                    volunteerInfo.type === "mission" ? (
                      <div key={i}>
                        <strong>Mission Info:</strong>
                        <p>Travel Restrictions: {volunteerInfo.travelRestrictions ? 'Yes' : 'No'}</p>
                        <p>Passport Country: {volunteerInfo.passportCountry?.name}</p>
                        <p>Years of Experience: {volunteerInfo.yearsOfExperience}</p>
                        {/* Add other mission fields here */}
                      </div>
                    ) : (
                      <div key={i}>
                        <strong>Skill Info:</strong>
                        <p>Educational Background: {volunteerInfo.educationalBackground}</p>
                        <p>Skills: {volunteerInfo.skills?.map(skill => skill.name).join(', ')}</p>
                        {/* Add other skill fields here */}
                      </div>
                    )
                  ) : null
                ))}
              </div>
            ))}
          </div>
        </>
      ) : (
                <div className="mx-10 md:w-3/5 xl:w-1/3 text-center">
                    <h1 className="text-5xl font-semibold text-primary">Admin Login</h1>
                    <div className="mt-6">
                        <input
                            type="text"
                            placeholder="Username"
                            className="px-4 py-2 mb-4 border rounded"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <br />
                        <input
                            type="password"
                            placeholder="Password"
                            className="px-4 py-2 mb-4 border rounded"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <br />
                        <button onClick={handleLogin} className="px-4 py-2 mt-4 text-white bg-blue-500 rounded">
                            Login
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminHome;
